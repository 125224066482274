import { imageAdapter } from '@/adapters/constructor/utils/image';

export default ({ data }, sections) => {
  const contentSection = sections.find(({ type }) =>
    ['VacanciesContent', 'ReferralContent'].includes(type),
  );

  return {
    title: data.title,
    image: imageAdapter(data.banner, data.banner_mobile),
    buttonScrollId: contentSection?.data?.id,
  };
};
